import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Tooltip,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import CardActionArea from "@mui/material/CardActionArea";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import Swal from "sweetalert2";
import { rejectPendingAdvocacyById } from "../../../../services/advocacy";
import { toast } from "react-toastify";
import SuspenseFallback from "../../../../components/SuspenseFallback";
import { getCurrentUser } from "../../../../services/auth";

const PendingAdvocacyTable = ({ data, setData }) => {
  const navigate = useNavigate();

  const user = getCurrentUser();

  const [expandedSlideId, setExpandedSlideId] = useState(null);

  const toggleSlideDescription = (id) => {
    setExpandedSlideId((prevId) => (prevId === id ? null : id));
  };

  const handleReject = (id) => {
    const advocacyCopy = [...data];

    try {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you really want to reject this Advocacy? This process cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#FF0000",
        confirmButtonText: "Delete",
      }).then((res) => {
        if (res.isConfirmed) {
          rejectPendingAdvocacyById(id, user.id).then((res) => {
            if (res && res.data.status === 1) {
              const filteredAdvocacy = data.filter((x) => x.id !== id);
              setData(filteredAdvocacy);
              toast.success(res.data.message);
            } else {
              toast.error(res.data.message);
            }
          });
        }
      });
    } catch (error) {
      if (error.response.data.statusCode === 404) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "This record may have already been deleted.",
        });
        setData(advocacyCopy);
      }
    }
  };

  const filteredSlides = data.filter((slide) => slide.pending);

  if (data) {
    return (
      <Box>
        <Grid container spacing={2}>
          {filteredSlides.map((slide) => (
            <Grid key={slide.id} item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  position: "relative",
                  width: "100%",
                  height: 250,
                  borderRadius: "10px",
                  transition: "all .3s",
                  "&:hover": {
                    boxShadow: "0px 0px 30px 1px rgba(0, 255, 117, 0.30)",
                  },
                }}
              >
                <CardActionArea
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <CardMedia
                    component="img"
                    height={250}
                    image={slide.imageFileName}
                    alt="Slide Image"
                    sx={{
                      width: "100%",
                      backgroundColor: "#1a1a1a",
                      borderRadius: "10px 10px 0 0",
                      transition: "all .2s",
                      "&:hover": {
                        transform: "scale(1.1)",
                        borderRadius: "25px 25px 0 0",
                      },
                    }}
                  />
                  <CardContent
                    sx={{
                      // position: "relative",
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                      position: "absolute",
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                      color: "#fff",
                      borderRadius: "0 0 10px 10px",
                      padding: "20px",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      justifyContent: "center",
                      p: 0,
                      "& > :first-of-type": {
                        marginBottom: "8px",
                        borderBottomLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                        color: "#fff",
                      },
                    }}
                  >
                    <Typography variant="h6" gutterBottom>
                      {slide.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ padding: "0 5px" }}
                    >
                      {slide.description.length > 20 &&
                      expandedSlideId !== slide.id
                        ? `${slide.description.slice(0, 20)}... `
                        : slide.description}
                      {slide.description.length > 20 && (
                        <span
                          onClick={() => toggleSlideDescription(slide.id)}
                          style={{ color: "red", cursor: "pointer" }}
                        >
                          {expandedSlideId === slide.id
                            ? "See Less"
                            : "See More"}
                          <ExpandMoreIcon
                            fontSize="small"
                            sx={{ verticalAlign: "middle", marginLeft: "1px" }}
                          />
                        </span>
                      )}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    position: "absolute",
                    top: 0,
                    right: 0,
                    p: 0,
                    zIndex: 1,
                  }}
                >
                  <Tooltip
                    title="View"
                    placement="left"
                    sx={{
                      borderRadius: 0,
                      background:
                        "radial-gradient(circle, rgba(218,213,213,0.6) 100%, rgba(245,245,245,2) 100%)",
                    }}
                  >
                    <IconButton
                      size="small"
                      color="info"
                      onClick={() =>
                        navigate(
                          `advocacy/edit/${slide.id}`
                        )
                      }
                    >
                      <RemoveRedEyeIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }
  return <SuspenseFallback />;
};

export default PendingAdvocacyTable;
