import { getRandomString, handleUnauthorizedError } from "./auth";
import http from "./http";

export async function allJob() {
  try {
    const randomString = await getRandomString();
    const response = await http.post("/jobpost/all", {
      randomString,
    });

    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      handleUnauthorizedError();
    }
    throw error;
  }
}

export async function getAllPendingJobs() {
  try {
    const randomString = await getRandomString();
    const response = await http.post("/jobpost/all/pending", {
      randomString,
    });

    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      handleUnauthorizedError();
    }
    throw error;
  }
}

export async function getJobById(id) {
  try {
    const randomString = await getRandomString();
    const response = await http.post(`/jobpost/${id}`, {
      randomString,
    });

    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      handleUnauthorizedError();
    }
    throw error;
  }
}

export function editJob(id, userId, form) {
  const emailType = localStorage.getItem("iReferSecureEmail");
  return http.patch(`/jobpost/update/${id}/${userId}`, form, {
    params: { emailType },
  });
}

export function addJobByUserId(id, job) {
  const emailType = localStorage.getItem("iReferSecureEmail");
  return http.post(`/jobpost/add/${id}`, job, {
    params: { emailType },
  });
}

export function deleteJobById(id, userId) {
  return http.delete(`/jobpost/delete/${id}/${userId}`);
}

export function rejectJobById(id, userId, remarks) {
  return http.delete(
    `/jobpost/deletePending/${id}/${userId}?remarks=${remarks}`
  );
}

export async function allJobImage() {
  try {
    const randomString = await getRandomString();
    const response = await http.post(`/jobpost/all/image`, {
      randomString,
    });

    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      handleUnauthorizedError();
    }
    throw error;
  }
}

export async function getJobByCategory(category) {
  try {
    const randomString = await getRandomString();
    const response = await http.post(`/jobpost/jobs/${category}`, {
      randomString,
    });

    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      handleUnauthorizedError();
    }
    throw error;
  }
}

export async function getJobWithoutCategory() {
  try {
    const randomString = await getRandomString();

    const response = await http.post(`/jobpost/category`, {
      randomString,
    });

    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      handleUnauthorizedError();
    }
    throw error;
  }
}

export function toggleActiveStatus(id, userId) {
  return http.patch(`/jobpost/status/hide/${id}/${userId}`);
}

export function toggleApproveStatus(id, userId) {
  return http.patch(`/jobpost/status/pending/${id}/${userId}`);
}
