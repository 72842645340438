import React, { useContext } from "react";
import Router from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LandingTabProvider from "./context/landingTabContext";
import BackdropComponent from "./components/BackdropComponent";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SettingContext } from "./context/settingContext";
import { red } from "@mui/material/colors";

const App = () => {
  const context = useContext(SettingContext);
  const { darkMode } = context;

  const theme = createTheme({
    palette: {
      primary: {
        main: red[900],
      },

      mode: darkMode ? "dark" : "light",
    },
  });

  return (
    <>
      <BackdropComponent />
      <ThemeProvider theme={theme}>
        <ToastContainer
          autoClose={2000}
          theme={darkMode ? "dark" : "light"}
          closeOnClick
        />
        <LandingTabProvider>
          <Router />
        </LandingTabProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
