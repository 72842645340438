import { Box, Card, Container, Tab, Tabs, useMediaQuery } from "@mui/material";
import React, { useContext } from "react";
//import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PendingIcon from "@mui/icons-material/Pending";
//import Referral from "./referral/Referral";

import PendingJobListPage from "./pendingJobs/jobs/PendingJobListPage";

import { LandingTabContext } from "../../../context/landingTabContext";

import DonutSmallIcon from "@mui/icons-material/DonutSmall";

import StarIcon from "@mui/icons-material/Star";
import PendingPromo from "./pendingPromo/PendingPromo";
import PendingAdvocacy from "./pendingAdvocacy/PendingAdvocacy";
import PendingMerch from "./pendingMerch/PendingMerch";

const PendingConfig = () => {
  const context = useContext(LandingTabContext);
  const { pendingTabValue, setPendingTabValue } = context;

  const handleChange = (event, newValue) => {
    setPendingTabValue(newValue);
  };
  const isXs = useMediaQuery("(max-width:768px)");

  const renderPage = () => {
    if (pendingTabValue === 0) {
      return <PendingJobListPage />;
    }
    if (pendingTabValue === 1) {
      return <PendingPromo />;
    }
    if (pendingTabValue === 2) {
      return <PendingAdvocacy />;
    }
    if (pendingTabValue === 3) {
      return <PendingMerch />;
    }
  };

  return (
    <div>
      <style>
        {`
        .tabIcon {
          transition: transform 1.0s ease;
        }

        .tabIcon:hover {
          transform: rotate(360deg);
        }
        `}
      </style>
      <Container maxWidth="100%">
        <Card sx={{ mb: 4 }}>
          <Tabs
            value={pendingTabValue}
            onChange={handleChange}
            variant={isXs ? "scrollable" : "fullWidth"}
            scrollButtons="auto"
          >
            <Tab
              icon={<PendingIcon className="tabIcon" />}
              iconPosition="start"
              label="Pending Jobs"
              value={0}
            />
            <Tab
              icon={<PendingIcon className="tabIcon" />}
              iconPosition="start"
              label="Pending Promo"
              value={1}
            />
            <Tab
              icon={<PendingIcon className="tabIcon" />}
              iconPosition="start"
              label="Pending Advocacy"
              value={2}
            />
            <Tab
              icon={<PendingIcon className="tabIcon" />}
              iconPosition="start"
              label="Pending Merch Item"
              value={3}
            />
          </Tabs>
        </Card>
      </Container>
      <Box>{renderPage()}</Box>
    </div>
  );
};

export default PendingConfig;
