import { Button, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router";

const PendingPromoForm = ({ onSubmit, initialValue, onDelete }) => {
  const [form, setForm] = useState(
    initialValue || {
      id: 0,
      title: "",
      description: "",
      imageFileName: "",
    }
  );

  const navigate = useNavigate();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Title"
          name="title"
          value={form.title}
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Description"
          name="description"
          value={form.description}
          multiline
          rows={6}
          disabled
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          fullWidth
        >
          Approve
        </Button>
      </Grid>
      <Grid item xs={12} md={4}>
        <Button variant="contained" color="error" onClick={onDelete} fullWidth>
          Reject
        </Button>
      </Grid>
      <Grid item xs={12} md={4}>
        <Button
          color="warning"
          variant="contained"
          fullWidth
          onClick={() => navigate("/irefer/pendingconfig")}
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};

export default PendingPromoForm;
