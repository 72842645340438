import { Card, Container, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import PendingMerchForm from "./PendingMerchForm";
import { useNavigate, useParams } from "react-router-dom";
import {
  approvePendingStatus,
  getMerchByUser,
  rejectMerchById,
} from "../../../../services/merch";
import Swal from "sweetalert2";
import { SettingContext } from "../../../../context/settingContext";
import { getCurrentUser } from "../../../../services/auth";
import { toast } from "react-toastify";
import SuspenseFallback from "../../../../components/SuspenseFallback";

const PendingMerchEdit = () => {
  const context = useContext(SettingContext);
  const { setBackdropOpen } = context;
  const [merchItem, setMerchItem] = useState(null);

  const user = getCurrentUser();
  const navigate = useNavigate();
  const params = useParams();

  const handleSubmit = async () => {
    try {
      await Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to approve this merch?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3CB043",
        confirmButtonText: "Yes",
      }).then((res) => {
        if (res.isConfirmed) {
          approvePendingStatus(+params.id, user.id).then((res) => {
            if (res && res.data.status === 1) {
              toast.success(res.data.message);
              navigate("/irefer/pendingconfig");
            } else {
              toast.error(res.data.message);
            }
          });
        }
      });
    } catch (error) {
      if (error.response.data.statusCode === 404) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "This Merch may have already been deleted.",
        });
      }
    }
  };

  const handleDelete = async () => {
    try {
      await Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to reject this Merch?",
        icon: "info",
        input: "text",
        inputPlaceholder: "Remarks(Optional)",
        inputAttributes: {
          maxLength: 500,
        },
        showCancelButton: true,
        confirmButtonColor: "#3CB043",
        confirmButtonText: "Yes",
      }).then((res) => {
        if (res.isConfirmed) {
          rejectMerchById(+params.id, user.id, res.value).then((res) => {
            if (res && res.data.status === 1) {
              toast.success(res.data.message);
              navigate("/irefer/pendingconfig");
            } else {
              toast.error(res.data.message);
            }
          });
        }
      });
    } catch (error) {
      if (error.response.data.statusCode === 404) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "This Merch may have already been deleted.",
        });
      }
    }
  };

  useEffect(() => {
    getMerchItem(+params.id);
  }, [params.id]);
  const getMerchItem = (id) => {
    getMerchByUser(id).then((res) => {
      if (res && res.data) {
        setMerchItem(res.data.object);
      }
    });
  };

  if (merchItem) {
    return (
      <Container maxWidth="100%">
        <Typography variant="h5" mb={2} fontWeight="bold">
          View Merch Item Details
        </Typography>
        <Card sx={{ padding: "20px" }}>
          <PendingMerchForm
            onSubmit={handleSubmit}
            onDelete={handleDelete}
            initialValue={{
              id: merchItem.id,
              imageFileName: merchItem.imageFileName,
              merch: merchItem.merch,
              stock: merchItem.stock,
              points: merchItem.points,
            }}
          />
        </Card>
      </Container>
    );
  }
  return <SuspenseFallback />;
};

export default PendingMerchEdit;
