import { getRandomString, handleUnauthorizedError } from "./auth";
import http from "./http";

export async function allAdvocacy() {
  try {
    const randomString = await getRandomString();
    const response = await http.post(`/advocacy/all`, {
      randomString,
    });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      handleUnauthorizedError();
    }
    throw error;
  }
}

export function editAdvocacy(id, userId, form) {
  const emailType = localStorage.getItem("iReferSecureEmail");
  return http.patch(`/advocacy/update/${id}/${userId}`, form, {
    params: { emailType },
  });
}

export async function getAdvocacyById(id) {
  try {
    const randomString = await getRandomString();
    const response = await http.post(`/advocacy/${id}`, {
      randomString,
    });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      handleUnauthorizedError();
    }
    throw error;
  }
}

export function addAdvocacyByUserId(id, image) {
  const emailType = localStorage.getItem("iReferSecureEmail");
  return http.post(`/advocacy/add/${id}`, image, {
    params: { emailType },
  });
}
export function deleteAdvocacyById(id, userId) {
  return http.delete(`/advocacy/delete/${id}/${userId}`);
}
export function rejectPendingAdvocacyById(id, userId, remarks) {
  return http.delete(`/advocacy/pending/${id}/${userId}?remarks=${remarks}`);
}
export function toggleActiveStatus(id, userId) {
  return http.patch(`/advocacy/status/active/${id}/${userId}`);
}

export function togglePendingStatus(id, userId) {
  return http.patch(`/advocacy/status/pending/${id}/${userId}`);
}

export async function getPendingAdvocacy() {
  try {
    const randomString = await getRandomString();
    const response = await http.post(`/advocacy/all/pending`, {
      randomString,
    });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      handleUnauthorizedError();
    }
    throw error;
  }
}
