import { getRandomString, handleUnauthorizedError } from "./auth";
import http from "./http";

export async function allPromoCarousel() {
  try {
    const randomString = await getRandomString();
    const response = await http.post("/promocarousel/all", { randomString });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      handleUnauthorizedError();
    }
    throw error;
  }
}

export function editPromoCarousel(id, userId, form) {
  const emailType = localStorage.getItem("iReferSecureEmail");
  return http.patch(`/promocarousel/update/${id}/${userId}`, form, {
    params: { emailType },
  });
}

export async function getPromoCarouselById(id) {
  try {
    const randomString = await getRandomString();
    const response = await http.post(`/promocarousel/${id}`, { randomString });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      handleUnauthorizedError();
    }
    throw error;
  }
}

export function addPromoCarouselByUserId(id, image) {
  const emailType = localStorage.getItem("iReferSecureEmail");
  return http.post(`/promocarousel/add/${id}`, image, {
    params: { emailType },
  });
}

export function deletePromoCarouselById(id, userId) {
  return http.delete(`/promocarousel/delete/${id}/${userId}`);
}

export function rejectPromoCarouselById(id, userId, remarks) {
  return http.delete(
    `/promocarousel/rejectPending/${id}/${userId}?remarks=${remarks}`
  );
}

export function toggleActiveStatus(id, userId) {
  return http.patch(`/promocarousel/status/active/${id}/${userId}`);
}

export function togglePendingStatus(id, userId) {
  return http.patch(`/promocarousel/status/pending/${id}/${userId}`);
}

export async function getPendingPromo() {
  try {
    const randomString = await getRandomString();
    const response = await http.post("/promocarousel/all/pending", {
      randomString,
    });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      handleUnauthorizedError();
    }
    throw error;
  }
}
