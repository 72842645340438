import { Card, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  deleteMerchById,
  getAllPendingMerchItems,
} from "../../../../services/merch";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import PendingMerchTable from "./PendingMerchTable";
import SuspenseFallback from "../../../../components/SuspenseFallback";

const PendingMerch = () => {
  const [merch, setMerch] = useState(null);

  useEffect(() => {
    getMerch();
  }, []);

  const getMerch = () => {
    getAllPendingMerchItems().then((res) => {
      if (res && res.data) {
        setMerch(res.data.object);
      }
    });
  };

  const handleDeleteMerch = (id) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you really want to delete this Merch item? This process cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#FF0000",
        confirmButtonText: "Delete",
      }).then((res) => {
        if (res.isConfirmed) {
          deleteMerchById(id).then((res) => {
            if (res && res.data.status === 1) {
              const filteredMerchItems = merch.filter((x) => x.id !== id);
              setMerch(filteredMerchItems);
              toast.success(res.data.message);
            } else {
              toast.error(res.data.message);
            }
          });
        }
      });
    } catch (error) {
      if (error.response && error.response.status) {
        alert(error.response.data.message);
      } else if (error.response && error.response.status === 404) {
        alert("This Merch Item may have already been deleted");
      }
    }
  };

  if (merch) {
    return (
      <Container maxWidth="100%">
        <Card sx={{ padding: "20px" }}>
          <PendingMerchTable
            data={merch}
            setData={setMerch}
            handleDelete={handleDeleteMerch}
          />
        </Card>
      </Container>
    );
  }
  return <SuspenseFallback />;
};

export default PendingMerch;
