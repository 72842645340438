import http from "./http";

export function getAllUserRoles() {
  return http.get("/userrole/all");
}

export function getUserRoleById(id) {
  return http.get(`/userrole/${id}`);
}

export function addUserRole(userId, data) {
  return http.post(`/userrole/add/${userId}`, data);
}

export function editUserRole(id, userId, data) {
  return http.patch(`/userrole/edit/${id}/${userId}`, data);
}

export function deleteUserRole(id, userId) {
  return http.delete(`/userrole/delete/${id}/${userId}`);
}
