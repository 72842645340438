import { getRandomString, handleUnauthorizedError } from "./auth";
import http from "./http";

export function getAllMerch() {
  return http.get("/merch/all");
}
export async function getMerchByUser(id) {
  try {
    const randomString = await getRandomString();
    const response = await http.post(`/merch/${id}`, { randomString });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      handleUnauthorizedError();
    }
    throw error;
  }
}

export function allMerchImage() {
  return http.get("/merch/all/image");
}

export function addMerchByUserId(id, form) {
  const emailType = localStorage.getItem("iReferSecureEmail");
  return http.post(`/merch/add/${id}`, form, {
    params: { emailType },
  });
}
export function editMerch(id, userId, form) {
  const emailType = localStorage.getItem("iReferSecureEmail");
  return http.patch(`/merch/edit/${id}/${userId}`, form, {
    params: { emailType },
  });
}
export function deleteMerchById(id, userId) {
  return http.delete(`/merch/delete/${id}/${userId}`);
}
export function toggleActiveStatus(id) {
  return http.patch(`/merch/status/present/${id}`);
}

export async function getAllPendingMerchItems() {
  try {
    const randomString = await getRandomString();
    const response = await http.post("/merch/all/pending", { randomString });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      handleUnauthorizedError();
    }
    throw error;
  }
}

export async function getAllApprovedMerchItems() {
  try {
    const randomString = await getRandomString();
    const response = await http.post("/merch/all/approved", { randomString });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      handleUnauthorizedError();
    }
    throw error;
  }
}

export function approvePendingStatus(id, userId) {
  return http.patch(`/merch/status/pending/${id}/${userId}`);
}

export function rejectMerchById(merchId, userId, remarks) {
  return http.delete(
    `/merch/rejectPending/${merchId}/${userId}?remarks=${remarks}`
  );
}
