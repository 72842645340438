import { Button, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import Joi from "joi";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const PendingAdvocacyForm = ({ onSubmit, initialValue, onReject }) => {
  const [form, setForm] = useState(
    initialValue || {
      id: 0,
      title: "",
      description: "",
    }
  );
  const [errors, setErrors] = useState({});

  const schema = Joi.object({
    title: Joi.string().required(),
    description: Joi.string().required(),
  });

  const handleChange = (e) => {
    e.preventDefault();
    setForm({ ...form, [e.target.name]: e.target.value });
    const { error } = schema
      .extract(e.target.name)
      .label(e.target.name.toUpperCase())
      .validate(e.target.value);
    if (error) {
      setErrors({
        ...errors,
        [e.target.name]: error.details[0].message,
      });
    } else {
      delete errors[e.target.name];
      setErrors(errors);
    }
  };
  const isFormInvalid = () => {
    const result = schema.validate(form);
    return !!result.error;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const formStr = JSON.stringify(form);
    formData.append("dataRaw", formStr);
    formData.append("file", file);
    onSubmit(formData);
  };

  const navigate = useNavigate();

  const [file, setFile] = useState(null);

  const handleFileUpload = (e) => {
    e.preventDefault();
    let fileSize = e.target.files[0].size / 1000;
    if (fileSize > 5000) {
      toast.error("File size exceeded required size");
    }
    const uploadedFile = e.target.files[0];
    const allowedTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/svg+xml",
    ];
    if (uploadedFile && allowedTypes.includes(uploadedFile.type)) {
      setFile(uploadedFile);
    } else {
      alert("Please upload a .jpg, .jpeg or .png file.");
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Title"
          name="title"
          value={form.title}
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Description"
          name="description"
          value={form.description}
          disabled
          multiline
          rows={6}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          fullWidth
        >
          Approve
        </Button>
      </Grid>
      <Grid item xs={12} md={4}>
        <Button
          variant="contained"
          color="error"
          onClick={() => onReject()}
          fullWidth
        >
          Reject
        </Button>
      </Grid>
      <Grid item xs={12} md={4}>
        <Button
          color="warning"
          variant="contained"
          fullWidth
          onClick={() => navigate("/irefer/pendingconfig")}
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};

export default PendingAdvocacyForm;
