import React from "react";
import { Card, Button, Grid, CardContent, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import SuspenseFallback from "../../../../../components/SuspenseFallback";

const PendingJobForm = ({ onSubmit, initialValue, onDelete }) => {
  const [jobForm, setJobForm] = useState(
    initialValue || {
      id: 0,
      jobTitle: "",
      jobDescription: "",
      category: "",
      jobPdf: "",
    }
  );

  const navigate = useNavigate();

  const jobDesc = jobForm?.jobDescription || "No description available";

  const formatDescription = (desc) => {
    // Split lines by newlines
    const lines = desc.split("\n").map((line, index) => {
      const trimmedLine = line.trim();

      // Check if the line matches the keywords as a whole line
      const keywordRegex =
        /^(JOB DESCRIPTIONS|JOB DESCRIPTION|QUALIFICATIONS|QUALIFICATION)(:)?$/gi;

      // Bold specific keywords if they appear as the only content in the line
      let formattedLine = trimmedLine.replace(keywordRegex, "<h3>$1$2</h3>");

      // Check if line starts with "* " (main bullet)
      if (formattedLine.startsWith("* ")) {
        // Replace "* " with main bullet symbol
        return `
          <li style="display: flex; align-items: flex-start; margin-left: 20px; margin-bottom: 10px;">
            <span style="margin-right: 8px; font-size: 20px;">&#8226;</span>
            <span>${formattedLine.replace("* ", "")}</span>
          </li>`;
      }

      // Check if line starts with "- " (sub-bullet)
      else if (formattedLine.startsWith("- ")) {
        // Replace "- " with sub-bullet symbol and indent the line
        return `
          <li style="display: flex; align-items: flex-start; margin-left: 40px; margin-bottom: 5px;">
            <span style="margin-right: 8px; font-size: 16px;">&#8212;</span>
            <span>${formattedLine.replace("- ", "")}</span>
          </li>`;
      }

      // For lines that don't start with "* " or "- ", return plain text wrapped in <li>
      return `<li style="margin-bottom: 10px;"><span>${formattedLine}</span></li>`;
    });

    // Return the formatted list
    return `<ul style="list-style: none; padding-left: 0; margin-left: 0;">${lines.join(
      ""
    )}</ul>`;
  };

  const formattedDescription = formatDescription(jobDesc);

  if (jobForm) {
    return (
      <Card
        sx={{
          overflow: "auto",
          width: "100%",
          height: "85vh",
          boxShadow: 2,
          "&:hover": {
            boxShadow: 8,
          },
          mr: 1,
        }}
      >
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ fontWeight: "bold" }}
          >
            {jobForm.jobTitle} (
            {jobForm.category ? jobForm.category : "No category"})
          </Typography>
          <Typography
            gutterBottom
            variant="body1"
            component="div"
            dangerouslySetInnerHTML={{ __html: formattedDescription }}
          />
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            {jobForm.jobPdf ? (
              <iframe
                src={jobForm.jobPdf + "#toolbar=0"}
                title="PDF Viewer"
                width="100%"
                height="550px"
              />
            ) : (
              <p>No PDF available</p>
            )}
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={onSubmit}
                fullWidth
              >
                Approve
              </Button>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                variant="contained"
                color="error"
                onClick={() => onDelete(jobForm.id)}
                fullWidth
              >
                Reject
              </Button>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                color="warning"
                variant="contained"
                fullWidth
                onClick={() => navigate("/irefer/pendingconfig")}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
  return <SuspenseFallback />;
};

export default PendingJobForm;
