import { Button, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const PendingMerchForm = ({ onSubmit, initialValue, onDelete }) => {
  const [form, setForm] = useState(
    initialValue || {
      id: 0,
      points: 0,
      merch: "",
      stock: 0,
      imageFileName: "",
    }
  );

  const navigate = useNavigate();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Merch Name"
          name="merch"
          value={form.merch}
          disabled
        />
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Points"
            name="points"
            value={form.points}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Stock"
            name="stock"
            value={form.stock}
            disabled
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12} md={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            fullWidth
          >
            Approve
          </Button>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            variant="contained"
            color="error"
            onClick={onDelete}
            fullWidth
          >
            Reject
          </Button>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            color="warning"
            variant="contained"
            fullWidth
            onClick={() => navigate("/irefer/pendingconfig")}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PendingMerchForm;
