import React, { useEffect, useState } from "react";
import { Card, Container } from "@mui/material";
import SuspenseFallback from "../../../../components/SuspenseFallback";
import {
  deleteAdvocacyById,
  getPendingAdvocacy,
} from "../../../../services/advocacy";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import PendingAdvocacyTable from "./PendingAdvocacyTable";

const PendingAdvocacy = () => {
  const [advocacy, setAdvocacy] = useState(null);

  useEffect(() => {
    getAdvocacy();
  }, []);

  const getAdvocacy = () => {
    getPendingAdvocacy().then((res) => {
      if (res && res.data) {
        setAdvocacy(res.data.object);
      }
    });
  };

  const handleDelete = (id) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you really want to delete this Advocacy? This process cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#FF0000",
        confirmButtonText: "Delete",
      }).then((res) => {
        if (res.isConfirmed) {
          deleteAdvocacyById(id).then((res) => {
            if (res && res.data.status === 1) {
              const filteredAdvocacy = advocacy.filter((x) => x.id !== id);
              setAdvocacy(filteredAdvocacy);
              toast.success(res.data.message);
            } else {
              toast.error(res.data.message);
            }
          });
        }
      });
    } catch (error) {
      if (error.response && error.response.status) {
        alert(error.response.data.message);
      } else if (error.response && error.response.status === 404) {
        alert("This Advocacy may have already been deleted");
      }
    }
  };

  if (advocacy) {
    return (
      <Container maxWidth="100%">
        <Card sx={{ padding: "20px" }}>
          <PendingAdvocacyTable
            data={advocacy}
            setData={setAdvocacy}
            handleDelete={handleDelete}
          />
        </Card>
      </Container>
    );
  }
  return <SuspenseFallback />;
};

export default PendingAdvocacy;
