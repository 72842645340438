import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import { BrowserRouter } from "react-router-dom";
import { Suspense } from "react";
import SuspenseFallback from "./components/SuspenseFallback";
import SettingProvider from "./context/settingContext";
import DataContextProvider from "./context/dataContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Suspense fallback={<SuspenseFallback />}>
      <React.StrictMode>
        <SettingProvider>
          <DataContextProvider>
            <App />
          </DataContextProvider>
        </SettingProvider>
      </React.StrictMode>
    </Suspense>
  </BrowserRouter>
);
