import { useState } from "react";
import { createContext } from "react";

export const LandingTabContext = createContext({});

const LandingTabProvider = ({ children }) => {
  const [landingTabValue, setLandingTabValue] = useState(0);
  const [pendingTabValue, setPendingTabValue] = useState(0);
  const [generalSettingsTabValue, setGeneralSettingsTabValue] = useState(0);
  const [emailTemplateSettingsTabValue, setEmailTemplateSettingsTabValue] =
    useState(0);
  const [merchTabValue, setMerchTabValue] = useState(0);
  const [legalDocumentsSettingsTabValue, setLegalDocumentsSettingsTabValue] =
    useState(0);
  const [referralTabValue, setReferralTabValue] = useState(0);
  const [userTabValue, setUserTabValue] = useState(0);
  const [userEmailTabValue, setUserEmailTabValue] = useState(0);
  const [adminEmailTabValue, setAdminEmailTabValue] = useState(0);
  const [referralUserTabValue, setReferralUserTabValue] = useState(0);
  const [merchOrderTabValue, setMerchOrderTabValue] = useState(0);
  const [userMerchOrderTabValue, setUserMerchOrderTabValue] = useState(0);
  const [rejectedReferralTabValue, setRejectedReferralTabValue] = useState(0);
  const [invalidReferralTabValue, setInvalidReferralTabValue] = useState(0);
  const [notEligibleReferralTabValue, setNotEligibleReferralTabValue] =
    useState(0);
  const [parkedReferralTabValue, setParkedReferralTabValue] = useState(0);

  return (
    <LandingTabContext.Provider
      value={{
        landingTabValue,
        setLandingTabValue,
        pendingTabValue,
        setPendingTabValue,
        generalSettingsTabValue,
        setGeneralSettingsTabValue,
        emailTemplateSettingsTabValue,
        setEmailTemplateSettingsTabValue,
        merchTabValue,
        setMerchTabValue,
        legalDocumentsSettingsTabValue,
        setLegalDocumentsSettingsTabValue,
        referralTabValue,
        setReferralTabValue,
        userTabValue,
        setUserTabValue,
        userEmailTabValue,
        setUserEmailTabValue,
        adminEmailTabValue,
        setAdminEmailTabValue,
        referralUserTabValue,
        setReferralUserTabValue,
        merchOrderTabValue,
        setMerchOrderTabValue,
        userMerchOrderTabValue,
        setUserMerchOrderTabValue,
        rejectedReferralTabValue,
        setRejectedReferralTabValue,
        invalidReferralTabValue,
        setInvalidReferralTabValue,
        notEligibleReferralTabValue,
        setNotEligibleReferralTabValue,
        parkedReferralTabValue,
        setParkedReferralTabValue,
      }}
    >
      {children}
    </LandingTabContext.Provider>
  );
};

export default LandingTabProvider;
