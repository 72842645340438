import { Card, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getCurrentUser } from "../../../../services/auth";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import SuspenseFallback from "../../../../components/SuspenseFallback";
import {
  getAdvocacyById,
  rejectPendingAdvocacyById,
  togglePendingStatus,
} from "../../../../services/advocacy";
import PendingAdvocacyForm from "./PendingAdvocacyForm";
import Swal from "sweetalert2";

const PendingAdvocacyEdit = () => {
  const user = getCurrentUser();
  const navigate = useNavigate();
  const params = useParams();

  const handleReject = async () => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you really want to reject this Advocacy? This process cannot be undone.",
        icon: "warning",
        input: "text",
        inputPlaceholder: "Remarks(Optional)",
        inputAttributes: {
          maxLength: 500,
        },
        showCancelButton: true,
        confirmButtonColor: "#FF0000",
        confirmButtonText: "Reject",
      }).then((res) => {
        if (res.isConfirmed) {
          rejectPendingAdvocacyById(+params.id, user.id, res.value).then(
            (res) => {
              if (res && res.data.status === 1) {
                toast.success(res.data.message);
                navigate("/irefer/pendingconfig");
              } else {
                toast.error(res.data.message);
                navigate("/irefer/pendingconfig");
              }
            }
          );
        }
      });
    } catch (error) {
      if (error.response.data.statusCode === 404) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "This record may have already been deleted.",
        });
      }
    }
  };

  const handleSubmit = async () => {
    try {
      await Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to approve this advocacy?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3CB043",
        confirmButtonText: "Yes",
      }).then((res) => {
        if (res.isConfirmed) {
          togglePendingStatus(+params.id, user.id).then((res) => {
            if (res && res.data.status === 1) {
              toast.success(res.data.message);
              navigate("/irefer/pendingconfig");
            } else {
              toast.error(res.data.message);
              navigate("/irefer/pendingconfig");
            }
          });
        }
      });
    } catch (error) {
      if (error.response.data.statusCode === 404) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "This Job may have already been deleted.",
        });
      }
    }
  };

  const [advocacy, setAdvocacy] = useState(null);
  useEffect(() => {
    getAdvocacy(+params.id);
  }, []);
  const getAdvocacy = (id) => {
    getAdvocacyById(id).then((res) => {
      if (res && res.data) {
        setAdvocacy(res.data.object);
      }
    });
  };
  if (advocacy) {
    return (
      <Container maxWidth="100%">
        <Typography variant="h5" mb={2} fontWeight="bold">
          Edit Advocacy
        </Typography>
        <Card sx={{ padding: "20px" }}>
          <PendingAdvocacyForm
            onSubmit={handleSubmit}
            onReject={handleReject}
            initialValue={{
              id: advocacy.id,
              title: advocacy.title,
              description: advocacy.description,
            }}
          />
        </Card>
      </Container>
    );
  }
  return <SuspenseFallback />;
};
export default PendingAdvocacyEdit;
