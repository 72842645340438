import { Box, Typography, Button, Container, CssBaseline } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import background from "../assets/images/White Orange Texture Paper Business Linktree Background.png";
import darkmode2 from "../assets/images/bg.jpg";
import white2 from "../assets/images/white2.jpg";
import lightlogo from "../assets/logos/lightlogo.png";
import logo_irefer2 from "../assets/logos/logo_irefer2.png";
import SuspenseFallback from "./SuspenseFallback";
import BackdropComponent from "./BackdropComponent";
import { toast } from "react-toastify";
import { resendVerification } from "../services/user";
import { SettingContext } from "../context/settingContext";

const ResendVerificationPage = ({ user }) => {
  const currentLogged = user.user;

  const boxStyles = {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const isMobile = useMediaQuery("(max-width: 600px)");

  const formStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundImage: isDarkMode ? `url(${darkmode2})` : `url(${white2})`,
    backgroundSize: "cover",
    backgroundColor: isDarkMode ? "#ffffff" : "#000000",
    padding: "10px",
    ...(isMobile && {
      minHeight: "90vh",
      backgroundImage: isDarkMode ? `url(${darkmode2})` : `url(${white2})`,
      backgroundColor: isDarkMode ? "#ffffff" : "#000000",
    }),
  };

  const [loading, setLoading] = useState(false);

  const { setBackdropOpen } = useContext(SettingContext);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(180);
  const baseUrl = window.location.origin;

  const handleResendOTP = async () => {
    setBackdropOpen(true);
    const formData = new FormData();
    formData.append("baseUrl", baseUrl);
    try {
      await resendVerification(currentLogged.id, formData).then((res) => {
        if (res.data && res.data.status === 1) {
          toast.success(res.data.message);
          setBackdropOpen(false);
          startCountdown();
        } else {
          toast.error(res.data.message);
          setBackdropOpen(false);
        }
      });
    } catch (error) {
      if (error.response.data.statusCode === 404) {
        alert("Unexpected error occurred");
      }
      setBackdropOpen(false);
    }
  };

  const startCountdown = () => {
    setResendDisabled(true);
    let timer = 180;
    const intervalId = setInterval(() => {
      timer--;
      setCountdown(timer);
      if (timer === 0) {
        clearInterval(intervalId);
        setResendDisabled(false);
      }
    }, 1000);
  };

  useEffect(() => {
    if (resendDisabled) {
      startCountdown();
    }
  }, [resendDisabled]);

  return (
    <>
      <CssBaseline />
      <BackdropComponent />
      {!loading ? (
        <Box sx={(boxStyles, formStyle)}>
          <Container component="main" sx={{ ...formStyle, minWidth: "unset" }}>
            <Box
              sx={{
                marginTop: 3,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                ...(isMobile && {
                  marginTop: 0,
                  marginBottom: 3,
                }),
              }}
            >
              {isDarkMode ? (
                <img src={lightlogo} alt="Logo" maxwidth="100" height="80" />
              ) : (
                <img src={logo_irefer2} alt="Logo" maxwidth="100" height="80" />
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  border: 1,
                  borderColor: "text.primary",
                  backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                      ? theme.palette.grey[100]
                      : theme.palette.grey[900],
                  ...(isMobile && {
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    border: 1,
                    borderColor: "text.primary",
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light"
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                  }),
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    border: 1,
                    borderColor: "text.primary",
                    width: "100%",
                    backgroundColor: "#a12219",
                  }}
                ></Box>
                <Box sx={{ mt: 1, textAlign: "center" }} margin={2}>
                  <Typography>
                    Hello{" "}
                    {`${currentLogged.firstName} ${currentLogged.lastName}`},
                    Welcome to our community! We noticed that you haven't
                    verified your email address yet. To complete the
                    registration process and gain access to our services, please
                    verify your email by clicking the link below:
                  </Typography>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ mt: 2, backgroundColor: "green" }}
                    onClick={handleResendOTP}
                    disabled={resendDisabled}
                  >
                    {resendDisabled
                      ? `Resend Verification in ${countdown} seconds`
                      : "Resend Verification"}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      ) : (
        <SuspenseFallback />
      )}
    </>
  );
};

export default ResendVerificationPage;
