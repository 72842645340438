import { Card, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getCurrentUser } from "../../../../services/auth";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import SuspenseFallback from "../../../../components/SuspenseFallback";
import {
  getPromoCarouselById,
  rejectPromoCarouselById,
  togglePendingStatus,
} from "../../../../services/promocarousel";
import PendingPromoForm from "./PendingPromoForm";
import Swal from "sweetalert2";

const PendingPromoEdit = () => {
  const user = getCurrentUser();
  const navigate = useNavigate();
  const params = useParams();

  const handleSubmit = async () => {
    try {
      await Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to approve this promotion?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3CB043",
        confirmButtonText: "Yes",
      }).then((res) => {
        if (res.isConfirmed) {
          togglePendingStatus(+params.id, user.id).then((res) => {
            if (res && res.data.status === 1) {
              toast.success(res.data.message);
              navigate("/irefer/pendingconfig");
            } else {
              toast.error(res.data.message);
            }
          });
        }
      });
    } catch (error) {
      if (error.response.data.statusCode === 404) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "This Promo may have already been deleted.",
        });
      }
    }
  };

  const handleDelete = async () => {
    try {
      await Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to reject this promo?",
        icon: "info",
        input: "text",
        inputPlaceholder: "Remarks(Optional)",
        inputAttributes: {
          maxLength: 500,
        },
        showCancelButton: true,
        confirmButtonColor: "#3CB043",
        confirmButtonText: "Yes",
      }).then((res) => {
        if (res.isConfirmed) {
          rejectPromoCarouselById(+params.id, user.id, res.value).then(
            (res) => {
              if (res && res.data.status === 1) {
                toast.success(res.data.message);
                navigate("/irefer/pendingconfig");
              } else {
                toast.error(res.data.message);
              }
            }
          );
        }
      });
    } catch (error) {
      if (error.response.data.statusCode === 404) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "This Job may have already been deleted.",
        });
      }
    }
  };
  const [promoCarousel, setPromoCarousel] = useState(null);
  useEffect(() => {
    getPromoCarousel(+params.id);
  }, [params.id]);
  const getPromoCarousel = (id) => {
    getPromoCarouselById(id).then((res) => {
      if (res && res.data) {
        setPromoCarousel(res.data.object);
      }
    });
  };

  if (promoCarousel) {
    return (
      <Container maxWidth="100%">
        <Typography variant="h5" mb={2} fontWeight="bold">
          Edit Promo
        </Typography>
        <Card sx={{ padding: "20px" }}>
          <PendingPromoForm
            onSubmit={handleSubmit}
            onDelete={handleDelete}
            initialValue={{
              id: promoCarousel.id,
              imageFileName: promoCarousel.imageFileName,
              title: promoCarousel.title,
              description: promoCarousel.description,
            }}
          />
        </Card>
      </Container>
    );
  }
  return <SuspenseFallback />;
};
export default PendingPromoEdit;
