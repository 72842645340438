import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getJobById,
  toggleApproveStatus,
  rejectJobById,
} from "../../../../../../services/job";
import { toast } from "react-toastify";
import SuspenseFallback from "../../../../../../components/SuspenseFallback";
import { Container, Typography } from "@mui/material";

import { getCurrentUser } from "../../../../../../services/auth";
import PendingJobForm from "../PendingJobForm";
import Swal from "sweetalert2";

const PendingEdit = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [job, setJob] = useState(null);
  const user = getCurrentUser();

  useEffect(() => {
    getJob(+params.id);
  }, []);

  const getJob = (id) => {
    getJobById(id).then((res) => {
      if (res && res.data.object) {
        setJob(res.data.object);
      }
    });
  };

  const handleDelete = (id) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you really want to reject this Job? This cannot be undone and will be removed in the system.",
        icon: "warning",
        input: "text",
        inputPlaceholder: "Remarks(Optional)",
        inputAttributes: {
          maxLength: 500,
        },
        showCancelButton: true,
        confirmButtonColor: "#FF0000",
        confirmButtonText: "Reject",
      }).then((res) => {
        if (res.isConfirmed) {
          rejectJobById(id, user.id, res.value).then((res) => {
            if (res.data && res.data.status === 1) {
              toast.error(res.data.message);
              navigate("/irefer/pendingconfig");
            } else {
              toast.error(res.data.message);
              navigate("/irefer/pendingconfig");
            }
          });
        }
      });
    } catch (error) {
      if (error.response.data.statusCode === 404) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "This record may have already been deleted.",
        });
      }
    }
  };

  const handleSubmit = async () => {
    try {
      await Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to approve this job?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3CB043",
        confirmButtonText: "Yes",
      }).then((res) => {
        if (res.isConfirmed) {
          toggleApproveStatus(+params.id, user.id).then((res) => {
            if (res && res.data.status === 1) {
              toast.success(res.data.message);
              navigate("/irefer/pendingconfig");
            } else {
              toast.error(res.data.message);
            }
          });
        }
      });
    } catch (error) {
      if (error.response.data.statusCode === 404) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "This Job may have already been deleted.",
        });
      }
    }
  };

  if (job) {
    return (
      <Container maxWidth="100%">
        <Typography variant="h5" mb={2} fontWeight="bold">
          Pending Job for Approval
        </Typography>
        <PendingJobForm
          onSubmit={handleSubmit}
          onDelete={handleDelete}
          initialValue={{
            id: job.id,
            jobDescription: job.jobDescription,
            jobTitle: job.jobTitle,
            category: job.category,
            jobPdf: job.pdfFileName,
          }}
        />
      </Container>
    );
  }
  return <SuspenseFallback />;
};

export default PendingEdit;
