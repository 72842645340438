import http from "./http";

export function getAllRoles() {
  return http.get("/adminroles/all");
}

export function getRoleById(id) {
  return http.get(`/adminroles/${id}`);
}

export function addRoles(id, data) {
  return http.post(`/adminroles/add/${id}`, data);
}

export function editRoles(id, userId, data) {
  return http.patch(`/adminroles/edit/${id}/${userId}`, data);
}

export function editRolesName(id, userId, data) {
  return http.patch(`/adminroles/edit/role/${id}/${userId}`, data);
}

export function deleteRoles(id, userId) {
  return http.delete(`/adminroles/delete/${id}/${userId}`);
}
