import { useEffect, useState } from "react";
import { createContext } from "react";
import { getAllRoles } from "../services/adminroles";
import SuspenseFallback from "../components/SuspenseFallback";
import { getAllUserRoles } from "../services/userrole";

export const DataContext = createContext({});

const DataContextProvider = ({ children }) => {
  const [referrals, setReferrals] = useState(null);
  const [roles, setRoles] = useState(null);
  const [userRole, setUserRole] = useState(null);

  const getAdminRoles = () => {
    getAllRoles().then((res) => {
      if (res && res.data) {
        setRoles(res.data.object);
      }
    });
  };

  const getUserRole = () => {
    getAllUserRoles().then((res) => {
      if (res && res.data) {
        setUserRole(res.data.object);
      }
    });
  };

  useEffect(() => {
    getAdminRoles();
    getUserRole();
  }, []);

  const values = {
    referrals,
    setReferrals,
    roles,
    setRoles,
    userRole,
    setUserRole,
  };

  if (roles && userRole) {
    return (
      <DataContext.Provider value={values}>{children}</DataContext.Provider>
    );
  }
  return <SuspenseFallback />;
};

export default DataContextProvider;
