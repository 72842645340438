import { getRandomString, handleUnauthorizedError } from "./auth";
import http from "./http";

// export function allUser() {
//   return http.get("/user/all");
// }
export async function allUser() {
  try {
    const randomString = await getRandomString();
    const response = await http.post(`/user/all`, {
      randomString,
    });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      handleUnauthorizedError();
    }
    throw error;
  }
}

export function editUser(id, form) {
  return http.patch(`/user/update/${id}`, form);
}

// export function getUserById(id) {
//   return http.get(`/user/${id}`);
// }
export async function getUserById(id) {
  try {
    const randomString = await getRandomString();
    const response = await http.post(`/user/${id}`, {
      randomString,
    });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      handleUnauthorizedError();
    }
    throw error;
  }
}

export function editUserStatus(id, form, userId) {
  return http.patch(`/user/editStatus/${id}/${userId}`, form);
}

export function editUserStatusAdmin(id, form) {
  return http.patch(`/user/editStatusAdmin/${id}`, form);
}

export function editUserStatusUser(id, form) {
  return http.patch(`/user/editStatusUser/${id}`, form);
}

export function changeUserPassword(id, form) {
  return http.patch(`/user/changePass/${id}`, form);
}

export function editUserRole(id, role, userId) {
  return http.patch(`/user/editRolesUser/${id}/${role}/${userId}`);
}

export function resendVerification(id, form) {
  const emailType = localStorage.getItem("iReferSecureEmail");
  return http.patch(`/user/${id}/resendVerification`, form, {
    params: { emailType },
  });
}

export function requestNewIdUpload(id, userId) {
  return http.patch(`/user/requestNewIdUpload/${id}/${userId}`);
}

export function updateValidId(id, data) {
  return http.patch(`/user/updateId/${id}`, data);
}

export function addRemarks(id, userId, status) {
  return http.post(`/user/addRemarks/${id}/${userId}`, { status });
}
