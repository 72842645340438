import React, { useEffect, useState } from "react";
import { Card, Container } from "@mui/material";
import PendingPromoTable from "../pendingPromo/PendingPromoTable";
import SuspenseFallback from "../../../../components/SuspenseFallback";
import {
  deletePromoCarouselById,
  getPendingPromo,
} from "../../../../services/promocarousel";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const PendingPromo = () => {
  const [promoCarousel, setPromoCarousel] = useState(null);

  useEffect(() => {
    getPromoCarousel();
  }, []);

  const getPromoCarousel = () => {
    getPendingPromo().then((res) => {
      if (res && res.data) {
        setPromoCarousel(res.data.object);
      }
    });
  };

  const handleDelete = (id) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you really want to delete this Promo? This process cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#FF0000",
        confirmButtonText: "Delete",
      }).then((res) => {
        if (res.isConfirmed) {
          deletePromoCarouselById(id).then((res) => {
            if (res && res.data.status === 1) {
              const filteredPromoCarousel = promoCarousel.filter(
                (x) => x.id !== id
              );
              setPromoCarousel(filteredPromoCarousel);
              toast.success(res.data.message);
            } else {
              toast.error(res.data.message);
            }
          });
        }
      });
    } catch (error) {
      if (error.response && error.response.status) {
        alert(error.response.data.message);
      } else if (error.response && error.response.status === 404) {
        alert("This Promo may have already been deleted");
      }
    }
  };

  if (promoCarousel) {
    return (
      <Container maxWidth="100%">
        <Card sx={{ padding: "20px" }}>
          <PendingPromoTable
            data={promoCarousel}
            setData={setPromoCarousel}
            handleDelete={handleDelete}
          />
        </Card>
      </Container>
    );
  }
  return <SuspenseFallback />;
};

export default PendingPromo;
