import React from "react";
import { useState, useEffect } from "react";
import { getAllPendingJobs } from "../../../../../services/job";

import { Card, Container } from "@mui/material";
import PendingJobList from "../jobs/PendingJobList";

import SuspenseFallback from "../../../../../components/SuspenseFallback";

const PendingJobListPage = () => {
  const [jobs, setJobs] = useState(null);

  useEffect(() => {
    getJobs();
  }, []);

  const getJobs = () => {
    getAllPendingJobs().then((res) => {
      if (res && res.data) {
        setJobs(
          res.data.object.sort((a, b) => new Date(b.date) - new Date(a.date))
        );
      }
    });
  };

  if (jobs) {
    return (
      <Container maxWidth="100%">
        <Card sx={{ padding: "20px" }}>
          <PendingJobList data={jobs} setData={setJobs} />
        </Card>
      </Container>
    );
  }
  return <SuspenseFallback />;
};

export default PendingJobListPage;
