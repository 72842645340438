import { Backdrop } from "@mui/material";
import React, { useContext } from "react";
import { SettingContext } from "../context/settingContext";

const BackdropComponent = () => {
  const styles = `
  .overlayseven {
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(74, 74, 74, 0.8);
    justify-content: center;
    align-items: center;
  }
  .overlayseven svg{
    height: 150px;
    width: 150px;
  }
  .overlayseven svg .seven:nth-child(1){
    stroke: maroon;
    stroke-width: 3px;
    fill: transparent;
    stroke-dasharray: 200;
    stroke-dashoffset: 200;
    animation: animate .7s linear infinite;
  }
  .overlayseven svg .seven:nth-child(2){
    stroke: white;
    stroke-width: 3px;
    fill: transparent;
    stroke-dasharray: 200;
    stroke-dashoffset: 200;
    animation: animate .7s linear infinite;
  }
  @keyframes animate {
    to{
      stroke-dashoffset: 0;
    }
  }
`;
  const context = useContext(SettingContext);
  const { backdropOpen } = context;
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
      >
        <div className="overlayseven">
          <style>{styles}</style>
          <svg>
            <polyline className="seven" points="70,20 20,20 70,106" />
            <polyline className="seven" points="70,126 120,40 70,40" />
          </svg>
        </div>
      </Backdrop>
    </div>
  );
};

export default BackdropComponent;
