import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCurrentUser } from "../../../../services/auth";
import Swal from "sweetalert2";
import {
  deleteMerchById,
  toggleActiveStatus,
} from "../../../../services/merch";
import { toast } from "react-toastify";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";

const PendingMerchTable = ({ data, setData }) => {
  const navigate = useNavigate();
  const user = getCurrentUser();

  const handleDelete = (id) => {
    const merchCopy = [...data];

    try {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you really want to delete this Merch item? This process cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#FF0000",
        confirmButtonText: "Delete",
      }).then((res) => {
        if (res.isConfirmed) {
          deleteMerchById(id, user.id).then((res) => {
            if (res && res.data.status === 1) {
              const filteredMerchItem = data.filter((x) => x.id !== id);
              setData(filteredMerchItem);
              toast.success(res.data.message);
            } else {
              toast.error(res.data.message);
            }
          });
        }
      });
    } catch (error) {
      if (error.response.data.statusCode === 404) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "This merch may have already been deleted.",
        });
        setData(merchCopy);
      }
    }
  };

  const handleSlideStatus = (id, pending) => {
    try {
      const updatedData = data.map((slide) =>
        slide.id === id ? { ...slide, pending: !pending } : slide
      );
      Swal.fire({
        title: `Are you sure you want to activate this slide?`,
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes",
        reverseButtons: false,
        confirmButtonColor: "#3CB043",
      }).then((res) => {
        if (res.isConfirmed) {
          setData(updatedData);

          toggleActiveStatus(id);
          toast.success(`Slide has been activated`);
        } else if (res.isDenied) {
          toast.error("Slide active status update cancelled");
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const filteredSlides = data.filter((slide) => slide.pending);

  return (
    <Box>
      <Grid container spacing={2}>
        {filteredSlides.map((slide) => (
          <Grid key={slide.id} item xs={12} sm={6} md={4}>
            <Card
              sx={{
                position: "relative",
                width: "100%",
                height: "100%",
                borderRadius: "10px",
                transition: "all .3s",
                "&:hover": {
                  boxShadow: "0px 0px 30px 1px rgba(0, 255, 117, 0.30)",
                },
              }}
            >
              <CardActionArea
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <CardMedia
                  component="img"
                  height="200"
                  image={slide.imageFileName}
                  alt="Slide Image"
                  sx={{
                    width: "100%",
                    backgroundColor: "#1a1a1a",
                    borderRadius: "10px 10px 0 0",
                    transition: "all .2s",
                    "&:hover": {
                      transform: "scale(1.1)",
                      borderRadius: "25px 25px 0 0",
                    },
                  }}
                />
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    color: "#fff",
                    borderRadius: "0 0 10px 10px",
                    padding: "12px",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    p: 0,
                    "& > :first-of-type": {
                      marginBottom: "8px",
                      borderBottomLeftRadius: "10px",
                      borderBottomRightRadius: "10px",
                      color: "#fff",
                    },
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    {slide.merch}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Quantity: {slide.stock}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#fff" }}>
                    Points: {slide.points}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  position: "absolute",
                  top: 0,
                  right: 0,
                  p: 0,
                  zIndex: 1,
                }}
              >
                <Tooltip
                  title="View Details"
                  placement="left"
                  sx={{
                    borderRadius: 0,
                    background:
                      "radial-gradient(circle, rgba(218,213,213,0.6) 100%, rgba(245,245,245,2) 100%)",
                  }}
                >
                  <IconButton
                    size="small"
                    color="info"
                    onClick={() => {
                      navigate(`/irefer/pendingconfig/merch/info/${slide.id}`);
                    }}
                  >
                    <RemoveRedEyeIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PendingMerchTable;
